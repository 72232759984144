exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-benefits-js": () => import("./../../../src/pages/benefits.js" /* webpackChunkName: "component---src-pages-benefits-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-clinicians-js": () => import("./../../../src/pages/clinicians.js" /* webpackChunkName: "component---src-pages-clinicians-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-docs-buttons-js": () => import("./../../../src/pages/docs/buttons.js" /* webpackChunkName: "component---src-pages-docs-buttons-js" */),
  "component---src-pages-docs-color-js": () => import("./../../../src/pages/docs/color.js" /* webpackChunkName: "component---src-pages-docs-color-js" */),
  "component---src-pages-docs-forms-js": () => import("./../../../src/pages/docs/forms.js" /* webpackChunkName: "component---src-pages-docs-forms-js" */),
  "component---src-pages-docs-index-js": () => import("./../../../src/pages/docs/index.js" /* webpackChunkName: "component---src-pages-docs-index-js" */),
  "component---src-pages-docs-lists-js": () => import("./../../../src/pages/docs/lists.js" /* webpackChunkName: "component---src-pages-docs-lists-js" */),
  "component---src-pages-docs-spacing-js": () => import("./../../../src/pages/docs/spacing.js" /* webpackChunkName: "component---src-pages-docs-spacing-js" */),
  "component---src-pages-docs-typography-js": () => import("./../../../src/pages/docs/typography.js" /* webpackChunkName: "component---src-pages-docs-typography-js" */),
  "component---src-pages-hipaanpp-js": () => import("./../../../src/pages/hipaanpp.js" /* webpackChunkName: "component---src-pages-hipaanpp-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-original-js": () => import("./../../../src/pages/index-original.js" /* webpackChunkName: "component---src-pages-index-original-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-patients-js": () => import("./../../../src/pages/patients.js" /* webpackChunkName: "component---src-pages-patients-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-templates-default-template-js": () => import("./../../../src/templates/default-template.js" /* webpackChunkName: "component---src-templates-default-template-js" */),
  "component---src-templates-news-template-js": () => import("./../../../src/templates/news-template.js" /* webpackChunkName: "component---src-templates-news-template-js" */)
}

